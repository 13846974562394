// Stacks
.stack-xxl > * + * {
	margin-block-start: $gap-xxl;
}

.stack-xl > * + * {
	margin-block-start: $gap-xl;
}

.stack-l > * + * {
	margin-block-start: $gap-l;
}

.stack-m > * + * {
	margin-block-start: $gap-m;
}

.stack > * + * {
	margin-block-start: $gap;
}

.stack-s > * + * {
	margin-block-start: $gap-s;
}

.stack-xxs > * + * {
	margin-block-start: $gap-xxs;
}

.stack-xs > * + * {
	margin-block-start: $gap-xs;
}