//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//
// ========================================================================
// Component
// ========================================================================

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	@include font-reg;
	@include font(18, 27);
	width: 100%;
	color: $text-black;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

%container,
.container {
	max-width: toRem(1296);
	margin-inline: auto;
	padding-inline: toRem(48);

	@include break(tablet) {
		padding-inline: toRem(32);
	}

	@include break(mobile) {
		padding-inline: toRem(16);
	}
}
