$ed-btn-max-width: toRem(300);

%ed-btn {
	@extend %p-small; 
	@include font-bold;
	display: block;
	text-align: center;
	padding: $gap-m;
	border: 1px solid;
	border-radius: $gap-xs;
	transition: all $transition;

	@include break(tablet) {
		max-width: $ed-btn-max-width;
	}

	@include break(mobile) {
		max-width: 100%;
	}
}

%ed-btn-primary,
.ed-btn-primary {
	@extend %ed-btn;
	color: $white;
	background-color: $purple;
	border-color: $purple;

	&:hover {
		color: $white;
		background-color: $purple;
	}

	&.centered {
		margin-inline: auto;
	}

	&-scroll {
		@extend %ed-btn-primary;

		&::after {
			content: '';
			display: inline-block;
			width: $gap-s;
			height: $gap-s;
			margin-left: $gap-s;
			background-image: url('/images/icons/arrow-white-down.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translateY($sliver);
			transition: transform $transition;
		}

		&:hover {
			color: $white;
			background-color: $purple;

			&::after {
				background-image: url('/images/icons/arrow-purple-down.svg');
			}
		}		
	}
}

%ed-btn-secondary,
.ed-btn-secondary {
	@extend %ed-btn;
	color: $purple;
	background-color: $white;
	border-color: $purple;

	&:hover {
		color: $white;
		background-color: $midnight;
	}

	&.centered {
		margin-inline: auto;
	}

	&-ext {
		@extend %ed-btn-secondary;

		&::after {
			content: '';
			display: inline-block;
			width: $gap-s;
			height: $gap-s;
			margin-left: $gap-s;
			background-image: url('/images/icons/arrow-purple-ext.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translateY($sliver);
			transition: transform $transition;
		}

		&:hover {

			&::after {
				background-image: url('/images/icons/arrow-white-ext.svg');
			}
		}
	}
}

%ed-btn-tertiary,
.ed-btn-tertiary {
	@extend %ed-btn;
	color: $white;
	background-color: $midnight;
	border-color: $white;

	&:hover {
		color: $white;
		background-color: $purple;
		border-color: $purple;
	}

	&-ext {
		@extend %ed-btn-tertiary;

		&::after {
			content: '';
			display: inline-block;
			width: $gap-s;
			height: $gap-s;
			margin-left: $gap-s;
			background-image: url('/images/icons/arrow-white-ext.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translateY($sliver);
			transition: transform $transition;
		}

		&:hover {

			&::after {
				background-image: url('/images/icons/arrow-white-ext.svg');
			}
		}
	}
}

%ed-btn-pill {
	@extend %ed-btn;
	@include font-reg;
	display: inline-block;
	padding: $gap-xxs $gap-s;
	border-radius: $gap-l;
}

.ed-btn-pill-primary {
	@extend %ed-btn-pill;
	color: $white;
	background-color: $purple;
	border: 1px solid $purple;

	&:hover {
		color: $white;
		background-color: $purple;
	}
}

.ed-btn-pill-secondary {
	@extend %ed-btn-pill;
	color: $purple;
	background-color: $white;
	border: 1px solid $purple;

	&:hover {
		color: $white;
		background-color: $midnight;
	}
}