
%h1-style {
	@include font-bold;
	@include font(72, 80);

	@include break(tablet) {
		@include font(64, 70);
	}

	@include break(tablet) {
		@include font(56, 62);
	}
}

h1, .h1-style {
	@extend %h1-style;
}

%h2-style {
	@include font-bold;
	@include font(48, 52);

	@include break(tablet) {
		@include font(42, 46);
	}

	@include break(tablet) {
		@include font(38, 42);
	}
}

h2, .h2-style {
	@extend %h2-style;
}

%h3-style {
	@include font-bold;
	@include font(36, 40);

	@include break(tablet) {
		@include font(32, 35);
	}

	@include break(tablet) {
		@include font(28, 31);
	}
}

h3, .h3-style {
	@extend %h3-style;
}

%h4-style {
	@include font-reg;
	@include font(20, 27);
}

h4, .h4-style {
	@extend %h4-style;
}

%h5-style {
	@include font-bold;
	@include font(18, 22);
}

h5, .h5-style {
	@extend %h5-style;
}

%p-style {
	@include font(18, 27);
}

p, .p-style {
	@extend %p-style;
}

%p-xl {
	@include font(24, 36);

	@include break(tablet) {
		@include font(22, 33);
	}
}

.p-xl {
	@extend %p-xl;
}

%p-large {
	@include font(20, 28);
}

.p-large {
	@extend %p-large;
}

%p-small {
	@include font(16, 24);
}

.p-small {
	@extend %p-small;
}

%p-xs {
	@include font(14, 18);
}

.p-xs {
	@extend %p-small;
}

strong, 
.bold {
	@include font-bold;
}

.tag {
	@include font(16, 22);
	text-transform: uppercase;
}

.text-white {
	color: $white;
}

.text-purple {
	color: $purple;
}

.text-center {
	text-align: center;
}