$ed-divider-padding: toRem(40);
$ed-ticket-btn-max-width: toRem(300);
$ed-side-border-radius: toRem(12);
$ed-lineup-trigger-image-size: toRem(120);;
$ed-lineup-trigger-icon-size: toRem(40);;

.ed {

	&-hero {
		position: relative;
		color: $white;
		padding-top: $gap;

		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: '';
			display: block;
			width: 100%;
			height: 30%;
			background-color: $white;

			@include break(mobile) {
				height: $gap-xxl;
			}
		}

		&-inner {
			@extend %container;
			position: relative;
			z-index: 1;
		}

		&-back {
			@extend %p-small;
			color: $white;

			&::before {
				content: '';
				display: inline-block;
				width: $gap-s;
				height: $gap-s;
				margin-right: $gap-xs;
				background-image: url('../images/icons/arrow-white-left.svg');
				background-size: contain;
				background-repeat: no-repeat;
				transform: translateY($sliver);
			}
		}

		&-text {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: $gap-l;

			@include break(mobile) {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&-details {
			text-align: right;
			white-space: nowrap;

			@include break(mobile) {
				text-align: left;
			}
		}

		&-location {
			@extend %p-xs;

			&::before {
				content: '';
				display: inline-block;
				width: 1rem;
				height: 1rem;
				margin-right: $gap-xxs;
				background-image: url('../images/icons/location-white.svg');
				background-size: contain;
				background-repeat: no-repeat;
				transform: translateY($sliver);
			}
		}

		&-image {
			aspect-ratio: 2 / 1;
			border-radius: 1rem;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&-divider {
		padding-top: $ed-divider-padding;
		border-top: 2px solid $border-gray;
	}
	
	&-grid {
		@extend %container;
		display: grid;
		grid-template-columns: 2fr 1fr;
		gap: $gap-m;

		@include break(tablet) {
			grid-template-columns: 1fr;
		}

		.mobile-only {
			display: none;

			@include break(tablet) {
				display: block;
			}
		}
	}

	&-intro {
		@extend %p-xl;
	}

	&-lineup-link {
		@include font-bold;
		position: relative;
		display: inline-block;
		color: $purple;
		padding: $gap-s $gap-xxl $gap-s $gap-m;
		background-color: $bg-gray;
		border-left: 2px solid $purple;

		&::after {
			position: absolute;
			top: 50%;
			right: $gap-m;
			content: '';
			display: inline-block;
			width: $gap-s;
			height: $gap-s;
			background-image: url('/images/icons/arrow-purple-down.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translateY(-50%);
			transition: transform $transition;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&-arrow-link {
		@include font-bold;
		position: relative;
		display: block;
		color: $purple;

		&::after {
			position: absolute;
			top: 50%;
			content: '';
			display: inline-block;
			width: $gap-s;
			height: $gap-s;
			margin-left: $gap-xs;
			background-image: url('/images/icons/link-arrow-purple.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translate(0, -50%);
			transition: transform $transition;
		}

		&:hover {
			color: $text-black;
			cursor: pointer;

			&::after {
				background-image: url('/images/icons/link-arrow-black.svg');
				transform: translate($gap-xxs, -50%);
			}
		}
	}

	&-accordion {

		&-item {
			padding: $gap-m;
			border: 1px solid $border-gray;
		}
	
		&-trigger {
			@include button-reset;
			@include font-reg;
			@extend %p-style;
			text-align: left;
			padding-right: $gap-l;
	
			&::after {
				position: absolute;
				top: 50%;
				right: 0;
				content: '';
				display: inline-block;
				width: $gap;
				height: $gap;
				margin-left: $gap;
				background-image: url('/images/icons/accordion-open.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				transform: translateY(-50%);
			}
	
			&:hover {
				&::after {
					transform: translateY(-50%);
				}
			}
	
			&:focus-visible {
				outline: 1px solid $purple;
				outline-offset: $gap-l;
			}
	
			&[aria-expanded=true] {
	
				&::after {
					background-image: url('/images/icons/accordion-close.svg');
				}
			}
		}
	
		&-panel {
	
			* {
				@extend %p-small;
	
				&:first-child {
					padding-top: $gap-xs;
				}
			}
	
			&-content {
				max-width: $max-width;
	
				a {
					text-decoration: underline;
					transition: color $transition;
	
					&:hover {
						color: $purple;
					}
				}
			}
		}
	}

	&-links {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: $gap-xs $gap-m;

		@include break(tablet) {
			grid-template-columns: 1fr;
		}
	}

	&-link {
		position: relative;
		display: block;
		padding: $gap-xs $gap-s;
		background-color: $bg-gray;

		&::after {
			position: absolute;
			top: 50%;
			right: $gap-s;
			content: '';
			display: inline-block;
			width: $gap-s;
			height: $gap-s;
			background-image: url('/images/icons/link-arrow-purple.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			transform: translate(0, -50%);
			transition: transform $transition;
		}

		&:hover {
			cursor: pointer;

			&::after {
				transform: translate($gap-xxs, -50%);
			}
		}
	}

	&-side {
		padding: $gap-l $gap $gap-xl $gap;
		background-color: $light-gray;
		border-radius: $ed-side-border-radius;

		&-divider {
			padding-top: $gap-m;
			border-top: 2px solid $border-gray;
		}
	}

	&-bold-link {
		@extend %p-small; 
		@include font-bold;
		display: inline-block;
		color: $purple;
		padding-bottom: $sliver;
		border-bottom: 2px solid $purple;
	}

	&-email-link {
		@extend %p-small; 
		display: block;
	}

	&-lineup {
		position: relative;
		color: $white;
		padding-block: $gap-xxl;
		background-color: $lineup-bg;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: $gap-xs;
			background-image: url('/images/ed-gradient-border.jpg');
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		&-inner {
			@extend %container;
		}

		&-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: $gap-m;

			@include break(mobile) {
				flex-direction: column;
				align-items: stretch;
			}

			.ed-btn-secondary {

				&:hover {
					color: $white;
					background-color: $purple;
				}
			}
		}

		&-item {
			gap: $gap-m;
			padding-top: $gap-l;
			border-top: 1px solid $border-dark-gray;

			&:first-child {
				padding-top: 0;
				border: 0;
			}
		}

		&-trigger {
			@include button-reset;
			@include font-reg;
			@extend %p-style;
			display: flex;
			align-items: center;
			gap: $gap-m;
			text-align: left;
			color: $white;

			@include break(tablet) {
				padding: $gap-s 0 0 $gap-s;
			}
	
			&::after {
				position: absolute;
				top: 50%;
				right: $gap-m;
				content: '';
				display: inline-block;
				width: $ed-lineup-trigger-icon-size;
				height: $ed-lineup-trigger-icon-size;
				background-image: url('/images/icons/lineup-accordion-open.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				transform: translateY(-50%);

				@include break(mobile) {
					right: $gap-s;
				}
			}
	
			&:hover {
				.ed-lineup-trigger-details h3 {
					text-decoration-color: $white;
				}
			}
	
			&:focus-visible {
				outline: 1px solid $purple;
				outline-offset: $gap-l;
			}
	
			&[aria-expanded="true"] {
				padding: $gap-xl $gap-xxl $gap-xl $gap-xl;
				background-color: $black;

				@include break(tablet) {
					padding: $gap-s $gap-m $gap-s $gap-s;
				}
	
				&::after {
					background-image: url('/images/icons/lineup-accordion-close.svg');
					transform: translateY(calc(-50% - $gap-m));
				}

				.ed-lineup-trigger-image {
					display: none;
				}
			}

			&-image {
				width: $ed-lineup-trigger-image-size;
				aspect-ratio: 1 / 1;
				pointer-events: none;

				@include break(mobile) {
					display: none;
				}
	
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-details {
				padding-right: calc($gap-xxl + $gap-xxl);
				pointer-events: none;

				h3 {
					text-decoration: underline;
					text-decoration-color: transparent;
					transition: text-decoration-color $transition;
				}
			}
		}
	
		&-panel {
			background-color: $black;

			&-content {
				display: flex;
				justify-content: space-between;	
				gap: $gap-xxl;
				padding: $gap-l $gap-xxl $gap-xxl $gap-xxl;

				@include break(tablet) {
					gap: $gap-l;
					padding: $gap-xs $gap-m $gap-m $gap-m;
				}

				@include break(mobile) {
					flex-direction: column;
				}
			}

			&-left {
				flex: 1;
			}

			&-right {
				flex: 1.6;

				@include break(tablet) {
					flex: 1;
				}
			}

			&-image {
				flex: 1;
				aspect-ratio: 1 / 1;
	
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&-tickets {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: $gap-s;
		}

		&-btn {
			@include font(18, 24);
			max-width: unset;
			padding: $gap-xs;
			border-radius: $gap-xs;
		}

		&-artist-url {
			margin-top: $gap-l;
			padding-top: $gap-l;
			border-top: 1px solid $border-dark-gray;
		}

		&-url-btn {
			@include font-reg;
			@include font(18, 24);
			max-width: unset;
			padding: $gap-xs;
			border-color: rgba($white, .25);
			border-radius: $gap-xxs;
		}

		&-video {
			aspect-ratio: 16 / 9;

			iframe {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}

.event-detail-series {
	.ed-hero {
		background: linear-gradient(99.85deg, #07253B 0%, #4554E2 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	}
}

.event-detail-experience {
	.ed-hero {
		background: linear-gradient(99.85deg, #441FAD 0%, #9A1A6F 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	}

	h1 {
		@include font-display;
		@include font(100, 100);

		@include break(tablet) {
			@include font(88, 88);
		}
	
		@include break(tablet) {
			@include font(78, 78);
		}
	}

	h2, .h2-style {
		@include font-display;
		@include font(72, 72);

		@include break(tablet) {
			@include font(64, 64);
		}
	
		@include break(tablet) {
			@include font(56, 56);
		}
	}

	h3, .h3-style {
		@include font-display;
		@include font(48, 48);

		@include break(tablet) {
			@include font(42, 42);
		}
	
		@include break(tablet) {
			@include font(37, 37);
		}
	}
}