$jas-footer-container-max-width: toRem(1536);
$jas-footer-logo-max-width: toRem(530);
$jas-footer-link-spacing: toRem(12);
$jas-footer-info-max-width: toRem(500);

.jas-footer {
	position: relative;
	padding-block: $gap-xxl;
	background-image: url('/images/jas-footer-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 100%;
		height: $gap-xs;
		background-image: url('/images/ed-gradient-border.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: 1;
	}

	.ed-btn-primary {
		@include font-reg;
		@include font(12, 12);
		text-transform: uppercase;
		letter-spacing: 0.1em;
		padding-block: $gap-s;

		@include break(mobile) {
			width: 100%;
		}
	}

	&-inner {
		width: 100%;
		max-width: $jas-footer-container-max-width;
		margin-inline: auto;
		padding-inline: $gap-xl;
		color: $white;

		@include break(tablet) {
			padding-inline: $gap-l;
		}

		@include break(mobile) {
			padding-inline: $gap-s;
		}
	}

	&-row {
		display: flex;
		justify-content: space-between;
		gap: $gap-l;

		@include break(laptop) {
			flex-direction: column;
		}

		&.top {
			@include break(laptop) {
				gap: $gap-l;
			}
		}

		&.bottom {
			@include break(laptop) {
				gap: $gap-xxl;
			}
		}

		div {
			flex: 1;
		}
	}

	&-logo {
		img {
			display: block;
			width: 100%;
			max-width: $jas-footer-logo-max-width;
		}
	}

	&-btns {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: $gap-s;

		@include break(laptop) {
			justify-content: flex-start;
		}

		@include break(mobile) {
			flex-direction: column;
		}
	}

	&-info {
		@include font(16, 24);
		max-width: $jas-footer-info-max-width;

		@include break(laptop) {
			display: none;
		}

		&.mobile {
			display: none;

			@include break(laptop) {
				display: block;
			}
		}
	}

	&-links {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		gap: $gap-xl;

		@include break(mobile) {
			flex-direction: column;
			align-items: stretch;
		}

		&-col {
			& > * + * {
				margin-block-start: $jas-footer-link-spacing;
			}
		}

		p {
			@include font-bold;
			@include font(12, 16);
			text-transform: uppercase;
			padding-bottom: $jas-footer-link-spacing;
			border-bottom: 1px solid rgba($white, .22);
		}

		a {
			@include font(15, 20);
			display: block;
			color: $white;
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: text-decoration-coloor $transition;

			&:hover {
				text-decoration-color: $white;
			}
		}
	}
}