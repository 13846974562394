//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================
// Component: Variables
// ========================================================================

// Colors
$white: #fff;
$black: #000;
$light-gray: #EFF2F5;
$medium-gray: #626361;
$dark-gray: #3A3A3A;
$bg-gray: #F6F6F6;
$border-gray: #CBD5E0;
$border-dark-gray: #2C2C2C;

$blue: #EFF2F5;
$midnight: #080D12;
$purple: #772882;

$text-white: $white;
$text-black: $dark-gray;
$text-blue: #EFF2F5;

$header-black: #2C2E33;
$lineup-bg: #1C1E21;

// misc
$max-width: toRem(650);
$transition: .3s ease;

// Spacing
$gap-xxl: toRem(64);
$gap-xl: toRem(48);
$gap-l: toRem(32);
$gap-m: toRem(24);
$gap: toRem(20);
$gap-s: toRem(16);
$gap-xs: toRem(8);
$gap-xxs: toRem(4);
$sliver: toRem(2);