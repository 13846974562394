@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tac_oneregular';
    src: url('../fonts/tacone/tacone-regular-webfont.woff2') format('woff2'),
         url('../fonts/tacone/tacone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// font mixins 
@mixin font-reg {
	font-family: 'open_sansregular', sans-serif;
	font-weight: normal;
}

@mixin font-bold {
	font-family: 'open_sansbold', sans-serif;
	font-weight: normal;
}

@mixin font-display {
	font-family: 'tac_oneregular', serif;
	font-weight: normal;
}