html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:last-child {
  margin-bottom: 0;
}

:first-child {
  margin-top: 0;
}

a {
  background-color: transparent;
}

a,
input,
button {
  -webkit-tap-highlight-color: fade(#000, 0%);
}

a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #000;
  text-decoration: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  white-space: nowrap;
  padding: 0;
}

em {
  font-style: italic;
}

ins {
  text-decoration: none;
}

q {
  font-style: italic;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

blockquote,
figure {
  margin: 0;
}

p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0;
}
* + p,
* + ul,
* + ol,
* + dl,
* + blockquote,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 20px;
}

ul,
ol {
  padding-left: 30px;
}
ul > li > ul,
ul > li > ol,
ol > li > ul,
ol > li > ol {
  margin: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  margin: 0;
  border: 0;
  border-top: 1px solid #000;
}
* + hr {
  margin-top: 20px;
}

address {
  font-style: normal;
}

pre {
  overflow: auto;
}
pre > code {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}

progress {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
}

[hidden],
template {
  display: none;
}

iframe {
  border: 0;
}

@font-face {
  font-family: "open_sansbold";
  src: url("../fonts/opensans/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "open_sansregular";
  src: url("../fonts/opensans/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tac_oneregular";
  src: url("../fonts/tacone/tacone-regular-webfont.woff2") format("woff2"), url("../fonts/tacone/tacone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5;
  width: 100%;
  color: #3A3A3A;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.ed-lineup-inner, .ed-grid, .ed-hero-inner, .el-hero-filters, .el-hero-content, .el-hero-title,
.container {
  max-width: 81rem;
  margin-inline: auto;
  padding-inline: 3rem;
}
@media screen and (max-width: 768px) {
  .ed-lineup-inner, .ed-grid, .ed-hero-inner, .el-hero-filters, .el-hero-content, .el-hero-title,
  .container {
    padding-inline: 2rem;
  }
}
@media screen and (max-width: 620px) {
  .ed-lineup-inner, .ed-grid, .ed-hero-inner, .el-hero-filters, .el-hero-content, .el-hero-title,
  .container {
    padding-inline: 1rem;
  }
}

h1, .h1-style {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  font-size: 4.5rem;
  line-height: 1.1111111111;
}
@media screen and (max-width: 768px) {
  h1, .h1-style {
    font-size: 4rem;
    line-height: 1.09375;
  }
}
@media screen and (max-width: 768px) {
  h1, .h1-style {
    font-size: 3.5rem;
    line-height: 1.1071428571;
  }
}

h2, .h2-style {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  font-size: 3rem;
  line-height: 1.0833333333;
}
@media screen and (max-width: 768px) {
  h2, .h2-style {
    font-size: 2.625rem;
    line-height: 1.0952380952;
  }
}
@media screen and (max-width: 768px) {
  h2, .h2-style {
    font-size: 2.375rem;
    line-height: 1.1052631579;
  }
}

h3, .h3-style {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 1.1111111111;
}
@media screen and (max-width: 768px) {
  h3, .h3-style {
    font-size: 2rem;
    line-height: 1.09375;
  }
}
@media screen and (max-width: 768px) {
  h3, .h3-style {
    font-size: 1.75rem;
    line-height: 1.1071428571;
  }
}

h4, .h4-style {
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.35;
}

h5, .h5-style {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.2222222222;
}

.ed-lineup-trigger, .ed-accordion-trigger, p, .p-style {
  font-size: 1.125rem;
  line-height: 1.5;
}

.ed-intro, .p-xl {
  font-size: 1.5rem;
  line-height: 1.5;
}
@media screen and (max-width: 768px) {
  .ed-intro, .p-xl {
    font-size: 1.375rem;
    line-height: 1.5;
  }
}

.p-large {
  font-size: 1.25rem;
  line-height: 1.4;
}

.ed-email-link, .ed-bold-link, .ed-accordion-panel *, .ed-hero-back, .ed-btn-pill-secondary, .ed-btn-pill-primary,
.ed-btn-tertiary-ext,
.ed-btn-tertiary,
.ed-btn-secondary-ext,
.ed-btn-secondary, .el-hero-search .el-btn-submit,
.ed-btn-primary-scroll,
.ed-btn-primary, .p-xs, .p-small {
  font-size: 1rem;
  line-height: 1.5;
}

.ed-hero-location {
  font-size: 0.875rem;
  line-height: 1.2857142857;
}

strong,
.bold {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
}

.tag {
  font-size: 1rem;
  line-height: 1.375;
  text-transform: uppercase;
}

.text-white {
  color: #fff;
}

.text-purple {
  color: #772882;
}

.text-center {
  text-align: center;
}

.stack-xxl > * + * {
  margin-block-start: 4rem;
}

.stack-xl > * + * {
  margin-block-start: 3rem;
}

.stack-l > * + * {
  margin-block-start: 2rem;
}

.stack-m > * + * {
  margin-block-start: 1.5rem;
}

.stack > * + * {
  margin-block-start: 1.25rem;
}

.stack-s > * + * {
  margin-block-start: 1rem;
}

.stack-xxs > * + * {
  margin-block-start: 0.25rem;
}

.stack-xs > * + * {
  margin-block-start: 0.5rem;
}

.ed-btn-pill-secondary, .ed-btn-pill-primary,
.ed-btn-tertiary-ext,
.ed-btn-tertiary,
.ed-btn-secondary-ext,
.ed-btn-secondary, .el-hero-search .el-btn-submit,
.ed-btn-primary-scroll,
.ed-btn-primary {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  display: block;
  text-align: center;
  padding: 1.5rem;
  border: 1px solid;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}
@media screen and (max-width: 768px) {
  .ed-btn-pill-secondary, .ed-btn-pill-primary,
  .ed-btn-tertiary-ext,
  .ed-btn-tertiary,
  .ed-btn-secondary-ext,
  .ed-btn-secondary, .el-hero-search .el-btn-submit,
  .ed-btn-primary-scroll,
  .ed-btn-primary {
    max-width: 18.75rem;
  }
}
@media screen and (max-width: 620px) {
  .ed-btn-pill-secondary, .ed-btn-pill-primary,
  .ed-btn-tertiary-ext,
  .ed-btn-tertiary,
  .ed-btn-secondary-ext,
  .ed-btn-secondary, .el-hero-search .el-btn-submit,
  .ed-btn-primary-scroll,
  .ed-btn-primary {
    max-width: 100%;
  }
}

.el-hero-search .el-btn-submit,
.ed-btn-primary-scroll,
.ed-btn-primary {
  color: #fff;
  background-color: #772882;
  border-color: #772882;
}
.el-hero-search .el-btn-submit:hover,
.ed-btn-primary-scroll:hover,
.ed-btn-primary:hover {
  color: #fff;
  background-color: #772882;
}
.el-hero-search .centered.el-btn-submit,
.centered.ed-btn-primary-scroll,
.ed-btn-primary.centered {
  margin-inline: auto;
}
.ed-btn-primary-scroll::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  background-image: url("/images/icons/arrow-white-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(0.125rem);
  transition: transform 0.3s ease;
}
.ed-btn-primary-scroll:hover {
  color: #fff;
  background-color: #772882;
}
.ed-btn-primary-scroll:hover::after {
  background-image: url("/images/icons/arrow-purple-down.svg");
}

.ed-btn-secondary-ext,
.ed-btn-secondary {
  color: #772882;
  background-color: #fff;
  border-color: #772882;
}
.ed-btn-secondary-ext:hover,
.ed-btn-secondary:hover {
  color: #fff;
  background-color: #080D12;
}
.centered.ed-btn-secondary-ext,
.ed-btn-secondary.centered {
  margin-inline: auto;
}
.ed-btn-secondary-ext::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  background-image: url("/images/icons/arrow-purple-ext.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(0.125rem);
  transition: transform 0.3s ease;
}
.ed-btn-secondary-ext:hover::after {
  background-image: url("/images/icons/arrow-white-ext.svg");
}

.ed-btn-tertiary-ext,
.ed-btn-tertiary {
  color: #fff;
  background-color: #080D12;
  border-color: #fff;
}
.ed-btn-tertiary-ext:hover,
.ed-btn-tertiary:hover {
  color: #fff;
  background-color: #772882;
  border-color: #772882;
}
.ed-btn-tertiary-ext::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  background-image: url("/images/icons/arrow-white-ext.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(0.125rem);
  transition: transform 0.3s ease;
}
.ed-btn-tertiary-ext:hover::after {
  background-image: url("/images/icons/arrow-white-ext.svg");
}

.ed-btn-pill-secondary, .ed-btn-pill-primary {
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 2rem;
}

.ed-btn-pill-primary {
  color: #fff;
  background-color: #772882;
  border: 1px solid #772882;
}
.ed-btn-pill-primary:hover {
  color: #fff;
  background-color: #772882;
}

.ed-btn-pill-secondary {
  color: #772882;
  background-color: #fff;
  border: 1px solid #772882;
}
.ed-btn-pill-secondary:hover {
  color: #fff;
  background-color: #080D12;
}

/* WordPress Core WYSIWYG Styles
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img {
  /* Alignments of the image */
}
a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
a img.alignnone {
  margin: 5px 20px 20px 0;
}
a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
  /* Alignments of the caption */
}
.wp-caption.alignnone, .wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}
.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}
.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  /* Focus state of .screen-reader-text */
}
.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.accordion-trigger {
  position: relative;
  display: block;
  width: 100%;
}
.accordion-panel {
  visibility: hidden;
  overflow: hidden;
  transition: height 0.3s ease;
}
.accordion-panel.open {
  visibility: visible;
}

.skiplink {
  position: absolute;
  top: -50px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}
.skiplink:focus-visible {
  top: 0;
}

.header {
  color: #fff;
  background-color: #2C2E33;
}

.jas-footer {
  position: relative;
  padding-block: 4rem;
  background-image: url("/images/jas-footer-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.jas-footer::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 0.5rem;
  background-image: url("/images/ed-gradient-border.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.jas-footer .ed-btn-primary {
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-block: 1rem;
}
@media screen and (max-width: 620px) {
  .jas-footer .ed-btn-primary {
    width: 100%;
  }
}
.jas-footer-inner {
  width: 100%;
  max-width: 96rem;
  margin-inline: auto;
  padding-inline: 3rem;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .jas-footer-inner {
    padding-inline: 2rem;
  }
}
@media screen and (max-width: 620px) {
  .jas-footer-inner {
    padding-inline: 1rem;
  }
}
.jas-footer-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
@media screen and (max-width: 1024px) {
  .jas-footer-row {
    flex-direction: column;
  }
}
@media screen and (max-width: 1024px) {
  .jas-footer-row.top {
    gap: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .jas-footer-row.bottom {
    gap: 4rem;
  }
}
.jas-footer-row div {
  flex: 1;
}
.jas-footer-logo img {
  display: block;
  width: 100%;
  max-width: 33.125rem;
}
.jas-footer-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .jas-footer-btns {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 620px) {
  .jas-footer-btns {
    flex-direction: column;
  }
}
.jas-footer-info {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 31.25rem;
}
@media screen and (max-width: 1024px) {
  .jas-footer-info {
    display: none;
  }
}
.jas-footer-info.mobile {
  display: none;
}
@media screen and (max-width: 1024px) {
  .jas-footer-info.mobile {
    display: block;
  }
}
.jas-footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 3rem;
}
@media screen and (max-width: 620px) {
  .jas-footer-links {
    flex-direction: column;
    align-items: stretch;
  }
}
.jas-footer-links-col > * + * {
  margin-block-start: 0.75rem;
}
.jas-footer-links p {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.3333333333;
  text-transform: uppercase;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}
.jas-footer-links a {
  font-size: 0.9375rem;
  line-height: 1.3333333333;
  display: block;
  color: #fff;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-coloor 0.3s ease;
}
.jas-footer-links a:hover {
  text-decoration-color: #fff;
}

.el-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.el-hero {
  position: relative;
}
.el-hero::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 3.5625rem;
  background-color: #fff;
  z-index: -1;
}
@media screen and (max-width: 620px) {
  .el-hero::after {
    display: none;
  }
}
.el-hero.announcement {
  padding-top: 2rem;
}
.el-hero.announcement .el-hero-bg::before {
  background-color: #fff;
  opacity: 0.2;
}
.el-hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.el-hero-bg::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.el-hero-bg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.el-hero-title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 4rem;
  z-index: 2;
}
.el-hero-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-bottom: 1.5rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), linear-gradient(100deg, #07253B 0%, #4554E2 100%);
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .el-hero-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    padding: 2rem;
  }
}
.el-hero-image {
  flex: 1;
  padding: 6rem;
}
@media screen and (max-width: 768px) {
  .el-hero-image {
    padding: 0;
  }
}
.el-hero-image img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .el-hero-image img {
    max-width: 18.75rem;
  }
}
@media screen and (max-width: 620px) {
  .el-hero-image img {
    max-width: 12.5rem;
  }
}
.el-hero-text {
  flex: 1.5;
  padding: 3rem;
}
@media screen and (max-width: 768px) {
  .el-hero-text {
    padding: 0;
  }
}
.el-hero-filters {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;
  background-color: #2C2E33;
}
@media screen and (max-width: 620px) {
  .el-hero-filters {
    flex-direction: column;
  }
}
.el-hero-search {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 620px) {
  .el-hero-search {
    flex-direction: column;
    gap: 0.5rem;
  }
}
.el-hero-search input {
  display: block;
  width: 100%;
  height: 3.125rem;
}
.el-hero-search .el-btn-submit {
  font-size: 1rem;
  line-height: 1;
  height: 3.125rem;
  padding: 1rem 2rem;
  border-radius: 0;
  white-space: nowrap;
}
@media screen and (max-width: 620px) {
  .el-hero-search .el-btn-submit {
    width: 100%;
  }
}
.el-hero-refinements {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
@media screen and (max-width: 620px) {
  .el-hero-refinements {
    justify-content: space-between;
  }
}
.el-hero-refinements select {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1;
  height: 3.125rem;
  padding-inline: 1rem;
}
@media screen and (max-width: 620px) {
  .el-hero-refinements select {
    width: 50%;
  }
}

.ed-hero {
  position: relative;
  color: #fff;
  padding-top: 1.25rem;
}
.ed-hero::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 30%;
  background-color: #fff;
}
@media screen and (max-width: 620px) {
  .ed-hero::after {
    height: 4rem;
  }
}
.ed-hero-inner {
  position: relative;
  z-index: 1;
}
.ed-hero-back {
  color: #fff;
}
.ed-hero-back::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-image: url("../images/icons/arrow-white-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(0.125rem);
}
.ed-hero-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
@media screen and (max-width: 620px) {
  .ed-hero-text {
    flex-direction: column;
    align-items: flex-start;
  }
}
.ed-hero-details {
  text-align: right;
  white-space: nowrap;
}
@media screen and (max-width: 620px) {
  .ed-hero-details {
    text-align: left;
  }
}
.ed-hero-location::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  background-image: url("../images/icons/location-white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateY(0.125rem);
}
.ed-hero-image {
  aspect-ratio: 2/1;
  border-radius: 1rem;
  overflow: hidden;
}
.ed-hero-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ed-divider {
  padding-top: 2.5rem;
  border-top: 2px solid #CBD5E0;
}
.ed-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
}
@media screen and (max-width: 768px) {
  .ed-grid {
    grid-template-columns: 1fr;
  }
}
.ed-grid .mobile-only {
  display: none;
}
@media screen and (max-width: 768px) {
  .ed-grid .mobile-only {
    display: block;
  }
}
.ed-lineup-link {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  position: relative;
  display: inline-block;
  color: #772882;
  padding: 1rem 4rem 1rem 1.5rem;
  background-color: #F6F6F6;
  border-left: 2px solid #772882;
}
.ed-lineup-link::after {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("/images/icons/arrow-purple-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}
.ed-lineup-link:hover {
  cursor: pointer;
}
.ed-arrow-link {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  position: relative;
  display: block;
  color: #772882;
}
.ed-arrow-link::after {
  position: absolute;
  top: 50%;
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  background-image: url("/images/icons/link-arrow-purple.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(0, -50%);
  transition: transform 0.3s ease;
}
.ed-arrow-link:hover {
  color: #3A3A3A;
  cursor: pointer;
}
.ed-arrow-link:hover::after {
  background-image: url("/images/icons/link-arrow-black.svg");
  transform: translate(0.25rem, -50%);
}
.ed-accordion-item {
  padding: 1.5rem;
  border: 1px solid #CBD5E0;
}
.ed-accordion-trigger {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  text-align: left;
  padding-right: 2rem;
}
.ed-accordion-trigger:hover {
  cursor: pointer;
}
.ed-accordion-trigger::after {
  position: absolute;
  top: 50%;
  right: 0;
  content: "";
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1.25rem;
  background-image: url("/images/icons/accordion-open.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
}
.ed-accordion-trigger:hover::after {
  transform: translateY(-50%);
}
.ed-accordion-trigger:focus-visible {
  outline: 1px solid #772882;
  outline-offset: 2rem;
}
.ed-accordion-trigger[aria-expanded=true]::after {
  background-image: url("/images/icons/accordion-close.svg");
}
.ed-accordion-panel *:first-child {
  padding-top: 0.5rem;
}
.ed-accordion-panel-content {
  max-width: 40.625rem;
}
.ed-accordion-panel-content a {
  text-decoration: underline;
  transition: color 0.3s ease;
}
.ed-accordion-panel-content a:hover {
  color: #772882;
}
.ed-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem 1.5rem;
}
@media screen and (max-width: 768px) {
  .ed-links {
    grid-template-columns: 1fr;
  }
}
.ed-link {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #F6F6F6;
}
.ed-link::after {
  position: absolute;
  top: 50%;
  right: 1rem;
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("/images/icons/link-arrow-purple.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(0, -50%);
  transition: transform 0.3s ease;
}
.ed-link:hover {
  cursor: pointer;
}
.ed-link:hover::after {
  transform: translate(0.25rem, -50%);
}
.ed-side {
  padding: 2rem 1.25rem 3rem 1.25rem;
  background-color: #EFF2F5;
  border-radius: 0.75rem;
}
.ed-side-divider {
  padding-top: 1.5rem;
  border-top: 2px solid #CBD5E0;
}
.ed-bold-link {
  font-family: "open_sansbold", sans-serif;
  font-weight: normal;
  display: inline-block;
  color: #772882;
  padding-bottom: 0.125rem;
  border-bottom: 2px solid #772882;
}
.ed-email-link {
  display: block;
}
.ed-lineup {
  position: relative;
  color: #fff;
  padding-block: 4rem;
  background-color: #1C1E21;
}
.ed-lineup::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 0.5rem;
  background-image: url("/images/ed-gradient-border.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ed-lineup-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}
@media screen and (max-width: 620px) {
  .ed-lineup-head {
    flex-direction: column;
    align-items: stretch;
  }
}
.ed-lineup-head .ed-btn-secondary:hover {
  color: #fff;
  background-color: #772882;
}
.ed-lineup-item {
  gap: 1.5rem;
  padding-top: 2rem;
  border-top: 1px solid #2C2C2C;
}
.ed-lineup-item:first-child {
  padding-top: 0;
  border: 0;
}
.ed-lineup-trigger {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
  appearance: none;
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  text-align: left;
  color: #fff;
}
.ed-lineup-trigger:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .ed-lineup-trigger {
    padding: 1rem 0 0 1rem;
  }
}
.ed-lineup-trigger::after {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  content: "";
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("/images/icons/lineup-accordion-open.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
}
@media screen and (max-width: 620px) {
  .ed-lineup-trigger::after {
    right: 1rem;
  }
}
.ed-lineup-trigger:hover .ed-lineup-trigger-details h3 {
  text-decoration-color: #fff;
}
.ed-lineup-trigger:focus-visible {
  outline: 1px solid #772882;
  outline-offset: 2rem;
}
.ed-lineup-trigger[aria-expanded=true] {
  padding: 3rem 4rem 3rem 3rem;
  background-color: #000;
}
@media screen and (max-width: 768px) {
  .ed-lineup-trigger[aria-expanded=true] {
    padding: 1rem 1.5rem 1rem 1rem;
  }
}
.ed-lineup-trigger[aria-expanded=true]::after {
  background-image: url("/images/icons/lineup-accordion-close.svg");
  transform: translateY(calc(-50% - 1.5rem));
}
.ed-lineup-trigger[aria-expanded=true] .ed-lineup-trigger-image {
  display: none;
}
.ed-lineup-trigger-image {
  width: 7.5rem;
  aspect-ratio: 1/1;
  pointer-events: none;
}
@media screen and (max-width: 620px) {
  .ed-lineup-trigger-image {
    display: none;
  }
}
.ed-lineup-trigger-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ed-lineup-trigger-details {
  padding-right: calc(4rem + 4rem);
  pointer-events: none;
}
.ed-lineup-trigger-details h3 {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
}
.ed-lineup-panel {
  background-color: #000;
}
.ed-lineup-panel-content {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  padding: 2rem 4rem 4rem 4rem;
}
@media screen and (max-width: 768px) {
  .ed-lineup-panel-content {
    gap: 2rem;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  }
}
@media screen and (max-width: 620px) {
  .ed-lineup-panel-content {
    flex-direction: column;
  }
}
.ed-lineup-panel-left {
  flex: 1;
}
.ed-lineup-panel-right {
  flex: 1.6;
}
@media screen and (max-width: 768px) {
  .ed-lineup-panel-right {
    flex: 1;
  }
}
.ed-lineup-panel-image {
  flex: 1;
  aspect-ratio: 1/1;
}
.ed-lineup-panel-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ed-lineup-tickets {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
.ed-lineup-btn {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  max-width: unset;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.ed-lineup-artist-url {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #2C2C2C;
}
.ed-lineup-url-btn {
  font-family: "open_sansregular", sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  max-width: unset;
  padding: 0.5rem;
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.25rem;
}
.ed-lineup-video {
  aspect-ratio: 16/9;
}
.ed-lineup-video iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.event-detail-series .ed-hero {
  background: linear-gradient(99.85deg, #07253B 0%, #4554E2 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.event-detail-experience .ed-hero {
  background: linear-gradient(99.85deg, #441FAD 0%, #9A1A6F 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.event-detail-experience h1 {
  font-family: "tac_oneregular", serif;
  font-weight: normal;
  font-size: 6.25rem;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .event-detail-experience h1 {
    font-size: 5.5rem;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .event-detail-experience h1 {
    font-size: 4.875rem;
    line-height: 1;
  }
}
.event-detail-experience h2, .event-detail-experience .h2-style {
  font-family: "tac_oneregular", serif;
  font-weight: normal;
  font-size: 4.5rem;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .event-detail-experience h2, .event-detail-experience .h2-style {
    font-size: 4rem;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .event-detail-experience h2, .event-detail-experience .h2-style {
    font-size: 3.5rem;
    line-height: 1;
  }
}
.event-detail-experience h3, .event-detail-experience .h3-style {
  font-family: "tac_oneregular", serif;
  font-weight: normal;
  font-size: 3rem;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .event-detail-experience h3, .event-detail-experience .h3-style {
    font-size: 2.625rem;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .event-detail-experience h3, .event-detail-experience .h3-style {
    font-size: 2.3125rem;
    line-height: 1;
  }
}