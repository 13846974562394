.accordion {

	&-trigger {
		position: relative;
		display: block;
		width: 100%;
	}

	&-panel {
		visibility: hidden;
		overflow: hidden;
		transition: height $transition;

		&.open {
			visibility: visible;
		}
	}
}