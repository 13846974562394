.skiplink {
	position: absolute;
	top: -50px;
	left: 0;
	transition: transform .3s ease-in-out;
	z-index: 999;

	&:focus-visible {
		top: 0;
	}
}