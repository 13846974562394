$el-hero-border-height: toRem(57);
$el-content-image-tablet-max-width: toRem(300);
$el-content-image-mobile-max-width: toRem(200);
$el-content-image-padding: toRem(96);
$el-form-item-height: toRem(50);

.el {

	&-sr-only:not(:focus):not(:active) {
		clip: rect(0 0 0 0); 
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap; 
		width: 1px;
	}

	&-hero {
		position: relative;

		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: '';
			display: block;
			width: 100%;
			height: $el-hero-border-height;
			background-color: $white;
			z-index: -1;

			@include break(mobile) {
				display: none;
			}
		}

		&.announcement {
			padding-top: $gap-l;

			.el-hero-bg {
				&::before {
					background-color: $white;
					opacity: .2
				}
			}
		}

		&-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background-color: $black;
				opacity: .5;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-title {
			@extend %container;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			padding: $gap-xxl;
			z-index: 2;
		}

		&-content {
			@extend %container;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: $white;
			margin-bottom: $gap-m;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(100deg, #07253B 0%, #4554E2 100%);
			z-index: 2;

			@include break(tablet) {
				flex-direction: column;
				align-items: flex-start;
				gap: $gap-m;
				padding: $gap-l;
			}
		}

		&-image {
			flex: 1;
			padding: $el-content-image-padding;

			@include break(tablet) {
				padding: 0;
			}

			img {
				display: block;
				width: 100%;

				@include break(tablet) {
					max-width: $el-content-image-tablet-max-width;
				}

				@include break(mobile) {
					max-width: $el-content-image-mobile-max-width;
				}
			}
		}

		&-text {
			flex: 1.5;
			padding: $gap-xl;

			@include break(tablet) {
				padding: 0;
			}
		}

		&-filters {
			@extend %container;
			display: flex;
			justify-content: space-between;
			gap: $gap-s;
			padding: $gap-l;
			background-color: $header-black;

			@include break(mobile) {
				flex-direction: column;
			}
		}

		&-search {
			display: flex;
			justify-content: space-between;
			width: 100%;

			@include break(mobile) {
				flex-direction: column;
				gap: $gap-xs;
			}

			input {
				display: block;
				width: 100%;
				height: $el-form-item-height;
			}

			.el-btn-submit {
				@extend %ed-btn-primary;
				@include font(16, 16);
				height: $el-form-item-height;
				padding: $gap-s $gap-l;
				border-radius: 0;
				white-space: nowrap;

				@include break(mobile) {
					width: 100%;
				}
			}
		}

		&-refinements {
			display: flex;
			justify-content: flex-end;
			gap: $gap-s;

			@include break(mobile) {
				justify-content: space-between;
			}

			select {
				@include font-bold;
				@include font(16, 16);
				height: $el-form-item-height;
				padding-inline: $gap-s;

				@include break(mobile) {
					width: 50%;
				}
			}
		}
	}
}