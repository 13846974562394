$base-font-size: 16;

@function toRem($value) {
    $remValue: math.div($value, $base-font-size) + rem; 
    @return $remValue;
}

// font size/line height
@mixin font($size, $line-height) {
	font-size: toRem($size);
	line-height: calc($line-height / $size);
}

// button reset
@mixin button-reset {
	padding: 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	text-shadow: none;
	box-shadow: none;
	appearance: none;

	&:hover {
		cursor: pointer;
	}
}

// breaks
$screen-breaks: (
	"mobile": "620px",
	"tablet": "768px",
	"laptop": "1024px",
	"desktop": "1440px"
);

@mixin break($width, $breaks: $screen-breaks) {

	@if map-has-key($breaks, $width) {
		$width: map-get($breaks, $width);
	}

	@media screen and (max-width: #{$width}) {
		@content;
	}
}